var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-inner-banner',{attrs:{"banner-image":_vm.pageBanner}}),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"container"},[_c('base-page-head',{attrs:{"page-head":_vm.pagehead}}),_c('div',{staticClass:"page-contents"},[_c('div',{staticClass:"sidenav-wrapper"},[_c('aside',{staticClass:"left-col"},[_c('account-nav')],1),_c('div',{staticClass:"right-col",class:{ 'no-steps': _vm.isIdeaEditOrResubmit }},[(!_vm.isIdeaEditOrResubmit)?_c('steps',{attrs:{"step":_vm.step,"tabs":_vm.tabs}}):_vm._e(),(
                                _vm.hasAllFieldsPopulated &&
                                    _vm.myIdea.country &&
                                    _vm.bucketCategoriesList &&
                                    _vm.bucketCategoriesList.results
                            )?_c(_vm.isIdeaEditOrResubmit
                                    ? 'EditOrResubmit'
                                    : _vm.currentTabComponent,{tag:"component",attrs:{"myIdea":_vm.myIdea,"countries":_vm.countries,"selectedObjectOptions":_vm.selectedObjectOptions,"isEditAprrovedPlannedOrImplemented":_vm.disableNonEditableFields,"data-aos":"fade-up","data-aos-delay":"1000","data-aos-easing":"ease","data-aos-duration":"1000"},on:{"submit":_vm.submit,"inputDisabled":_vm.setDisabled}},[_c('submit-idea-footer',{attrs:{"step":_vm.step,"tabs":_vm.tabs,"disabled":_vm.disabled,"isIdeaEditOrResubmit":_vm.isIdeaEditOrResubmit},on:{"back":_vm.back}})],1):_vm._e()],1)])])],1)]),_c('b-modal',{ref:"submitIdea",attrs:{"id":"submitIdea","hide-footer":""},on:{"hidden":function($event){return _vm.toggleModal()}}},[_c('div',{staticClass:"submitIdea"},[(
                    _vm.dashboardSettings &&
                        _vm.dashboardSettings.dashboard_settings
                )?_c('div',{staticClass:"submitIdea__title",domProps:{"innerHTML":_vm._s(_vm.getPopupText('title'))}}):_vm._e(),(
                    _vm.dashboardSettings &&
                        _vm.dashboardSettings.dashboard_settings
                )?_c('div',{staticClass:"submitIdea__sub-title",domProps:{"innerHTML":_vm._s(_vm.getPopupText('sub_title'))}}):_vm._e(),_c('div',{staticClass:"submitIdea__image"},[_c('img',{staticClass:"img-fluid",attrs:{"loading":"lazy","src":require("@/assets/images/submitidea-img.png"),"alt":""}}),_c('span',[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/svg/pattern.svg"),"alt":""}})])]),_c('div',{staticClass:"inline-buttons"},[(!_vm.isIdeaEditOrResubmit)?_c('a',{staticClass:"btn btn-pink",attrs:{"href":_vm.$router.resolve({ name: 'submit-idea' }).href}},[_vm._v(" "+_vm._s(_vm.$t('Add another Idea'))+" ")]):(
                        _vm.ideaObject && _vm.ideaObject.state === 'resubmit'
                    )?_c('router-link',{staticClass:"btn btn-pink",attrs:{"to":{
                        name: 'user-ideas',
                    }}},[_vm._v(" "+_vm._s(_vm.$t('View My Ideas'))+" ")]):_vm._e(),(
                        _vm.ideaObject &&
                            (_vm.ideaObject.state === 'approved' ||
                                _vm.ideaObject.state === 'planned' ||
                                _vm.ideaObject.state === 'implemented')
                    )?_c('a',{staticClass:"btn btn-primary",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$refs.submitIdea.hide()}}},[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")]):(
                        _vm.ideaObject && _vm.ideaObject.state === 'resubmit'
                    )?_c('a',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.redirectToCMS()}}},[_vm._v(" "+_vm._s(_vm.$t('Go to homepage'))+" ")]):_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                        name: 'user-ideas',
                    }}},[_vm._v(" "+_vm._s(_vm.$t('Go to homepage'))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }