<template>
    <base-form ref="editOrResubmitForm" @submit="submit">
        <div
            v-if="
                ideaObject &&
                    ideaObject.state &&
                    ideaObject.state === 'resubmit' &&
                    ideaObject[`resubmit_feedback`]
            "
            class="ideaDetail__head--status"
        >
            <div v-html="ideaObject[`resubmit_feedback`]" />
        </div>
        <base-input
            ref="ideaTitle"
            :label="$t('Idea title')"
            name="Title"
            :placeholder="$t('Please enter the idea title')"
            :value="myIdea.title"
            v-model="myIdea.title"
            :rules="
                isEditAprrovedPlannedOrImplemented
                    ? ''
                    : 'specialCharactersNotAllowed'
            "
            :required="!isEditAprrovedPlannedOrImplemented"
            :disabled="isEditAprrovedPlannedOrImplemented"
        />
        <base-textarea
            :label="$t('Give a detailed example of how this idea would work')"
            name="Description"
            :placeholder="
                $t('Explain the idea in detail; elaborate as much as you can')
            "
            :value="myIdea.description"
            v-model="myIdea.description"
            :required="!isEditAprrovedPlannedOrImplemented"
            :disabled="isEditAprrovedPlannedOrImplemented"
        />
        <div
            v-if="myIdea.category.zainers_invloved"
            class="form-group checkbox"
            :class="{
                'disabled-checkbox-label': isEditAprrovedPlannedOrImplemented,
            }"
        >
            <input
                type="checkbox"
                name="radio"
                v-model="myIdea.invovle_in_implementation"
                :disabled="isEditAprrovedPlannedOrImplemented"
            />
            <label
                class="control-label"
                @click="
                    myIdea.invovle_in_implementation = !myIdea.invovle_in_implementation
                "
            >
                {{
                    $t('I would like to be involved in the idea implementation')
                }}
            </label>
        </div>
        <div v-for="(field, i) in ideaFormFields.fields" :key="i">
            <base-input
                v-if="field.type === 'singleline'"
                :label="field[`label_${$i18n.locale}`]"
                :name="field.name"
                :placeholder="field[`help_text_${$i18n.locale}`]"
                :value="myIdea.form_data[field.name]"
                v-model="myIdea.form_data[field.name]"
                :required="
                    !isEditAprrovedPlannedOrImplemented && field.required
                "
                :disabled="isEditAprrovedPlannedOrImplemented"
            />
            <base-textarea
                v-if="field.type === 'multiline'"
                :label="field[`label_${$i18n.locale}`]"
                :name="field.name"
                :placeholder="field[`help_text_${$i18n.locale}`]"
                :value="myIdea.form_data[field.name]"
                v-model="myIdea.form_data[field.name]"
                :required="
                    !isEditAprrovedPlannedOrImplemented && field.required
                "
                :disabled="isEditAprrovedPlannedOrImplemented"
            />
            <base-check-box
                v-if="field.type === 'checkbox'"
                :name="field.name"
                :value="myIdea.form_data[field.name] ? true : false"
                :options="getOptions(field[`label_${$i18n.locale}`])"
                :required="
                    !isEditAprrovedPlannedOrImplemented && field.required
                "
                @input="setCheckboxBaseValue"
                :type="field.type"
                :disabled="isEditAprrovedPlannedOrImplemented"
            ></base-check-box>

            <div v-if="field.type === 'checkboxes'" class="form-group">
                <label class="control-label">
                    {{ field[`label_${$i18n.locale}`] }}
                    <span v-if="field.required">*</span>
                </label>
                <base-multiple-checkbox
                    :name="field.name"
                    :value="myIdea.form_data[field.name]"
                    :options="getOptions(field[`choices_${$i18n.locale}`])"
                    :required="
                        !isEditAprrovedPlannedOrImplemented && field.required
                    "
                    @input="setMultipleCheckBoxValue"
                    :type="field.type"
                    :disabled="isEditAprrovedPlannedOrImplemented"
                ></base-multiple-checkbox>
            </div>

            <div v-if="field.type === 'radio'" class="form-group">
                <base-radio
                    :label="field[`label_${$i18n.locale}`]"
                    :name="field.name"
                    :value="myIdea.form_data[field.name]"
                    :options="getOptions(field[`choices_${$i18n.locale}`])"
                    :required="
                        !isEditAprrovedPlannedOrImplemented && field.required
                    "
                    @input="setBaseValue"
                    :disabled="isEditAprrovedPlannedOrImplemented"
                ></base-radio>
            </div>

            <div class="form-group " v-if="field.type === 'dropdown'">
                <label class="control-label">
                    {{ field[`label_${$i18n.locale}`] }}
                    <span v-if="field.required">*</span>
                </label>
                <base-select
                    v-model="myIdea.form_data[field.name]"
                    :options="getOptions(field[`choices_${$i18n.locale}`])"
                    :required="
                        !isEditAprrovedPlannedOrImplemented && field.required
                    "
                    :disabled="isEditAprrovedPlannedOrImplemented"
                ></base-select>
            </div>

            <div class="form-group " v-if="field.type === 'multiselect'">
                <label class="control-label">
                    {{ field[`label_${$i18n.locale}`] }}
                    <span v-if="field.required">*</span>
                </label>
                <base-multiple-drop-down
                    :name="field.name"
                    placeholder="Select Options"
                    :value="myIdea.form_data[field.name]"
                    :options="
                        getMultipleDropDownOptions(
                            field[`choices_${$i18n.locale}`],
                        )
                    "
                    :required="
                        !isEditAprrovedPlannedOrImplemented && field.required
                    "
                    @input="setBaseValue"
                    :disabled="isEditAprrovedPlannedOrImplemented"
                ></base-multiple-drop-down>
            </div>
        </div>
        <div class="uploadFiles">
            <base-drop-zone
                v-if="isEditAprrovedPlannedOrImplemented"
                :label="$t('Upload Idea Image')"
                :dropzonesettings="{
                    acceptedFiles: 'image/*',
                }"
                name="medias"
                :existFiles="mediaFiles"
                :error="mediaFilesError"
                :show-change-name="true"
                :isWorkingOnDocument="isWorkingOnDocument"
                :hideDeleteButton="isEditAprrovedPlannedOrImplemented"
                :sortable="true"
                @input="inputMediaFiles"
                @fileAdde="fileAdde"
                @deleteInput="deleteMediaFiles"
                @onBlur="changeMediaFileName"
                @onSort="changeMediaFileOrder"
            ></base-drop-zone>
            <base-drop-zone
                :label="$t('Upload supporting documents and images')"
                name="documents"
                :existFiles="documents"
                :error="documentsError"
                :show-change-name="true"
                :isWorkingOnDocument="isWorkingOnDocument"
                :hideDeleteButton="isEditAprrovedPlannedOrImplemented"
                :disabled="isEditAprrovedPlannedOrImplemented"
                @input="inputDocumentsFiles"
                @fileAdde="fileAdde"
                @deleteInput="deleteDocumentFiles"
                @onBlur="changeDocumentFileName"
            ></base-drop-zone>
        </div>
        <base-multiple-select
            :label="$t('Add your team members')"
            :labelIcon="labelIcon"
            name="members"
            :placeholder="$t('Add your team members')"
            :value="selectedOptions"
            v-model="selectedOptions"
            :required="false"
            :options="options"
            options_value_key="id"
            :fields="['photo', 'display_name', 'email', 'country']"
            @change="change"
            @input="input"
            @deleteMember="deleteMember"
            :disabled="isEditAprrovedPlannedOrImplemented"
        />
        <slot></slot>
    </base-form>
</template>

<script>
import memberImage from '@/assets/images/svg/person.svg'
import userIcon from '@/assets/images/svg/user-icon-2.svg'
import { IdeasHelper } from '@/common/crud-helpers/ideas'
import { UsersHelper } from '@/common/crud-helpers/users'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { Notify } from 'notiflix'
import { localize } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
    props: {
        myIdea: {
            type: Object,
        },
        selectedObjectOptions: {
            type: Array,
        },
        isEditAprrovedPlannedOrImplemented: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.labelIcon = userIcon
        this.selectedOptions = cloneDeep(this.selectedObjectOptions)
        this.selectedOptions.forEach(option => {
            option.photo = option.photo ? option.photo : memberImage
        })
        this.setDocumentsFiles()
        this.setMediaFiles()
    },
    mounted() {
        localize(this.$i18n.locale, this.dictionary[this.$i18n.locale])
        this.$refs.ideaTitle.$el.getElementsByTagName('input')[0].focus()
    },
    data() {
        return {
            selectedOptions: [],
            options: [],
            members: [],
            mediaFiles: [],
            mediaFilesError: '',
            documents: [],
            documentsError: '',
            isWorkingOnDocument: false,
            isFileSorted: false,
        }
    },
    methods: {
        fileAdde() {
            this.$emit('inputDisabled', true)
        },
        inputMediaFiles(data) {
            this.mediaFilesError = ''
            if (!this.myIdea.medias) {
                this.myIdea.medias = []
            }
            let file = data
            file.name = ''
            this.myIdea.medias.push(file)
            this.$emit('inputDisabled', false)
        },
        async changeMediaFileName(name, index) {
            let media = this.myIdea.medias[index]
            if (media.created) {
                this.isWorkingOnDocument = true
                await IdeasHelper.updateIdeaMedia({
                    id: media.temp_file_obj_id,
                    name: name,
                })
                    .then(() => {
                        this.myIdea.medias[index].name = name
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => {
                        this.isWorkingOnDocument = false
                    })
            } else {
                this.myIdea.medias[index].name = name
            }
        },
        changeMediaFileOrder(oldIndex, newIndex) {
            this.myIdea.medias.splice(
                newIndex,
                0,
                this.myIdea.medias.splice(oldIndex, 1)[0],
            )
            this.myIdea.medias.forEach((item, index) => {
                item.sort_order = index
            })
            this.isFileSorted = true
        },
        deleteMediaFiles(val) {
            this.myIdea.medias.splice(val, 1)
        },
        setMediaFiles() {
            if (this.myIdea.medias) {
                this.myIdea.medias.forEach(media => {
                    const mediaTemp = {
                        filename: this.getFileName(media.filename),
                        path: cloneDeep(media.path),
                        name: media.name ? media.name : '',
                        temp_file_obj_id: media.temp_file_obj_id,
                    }
                    if (media.created) {
                        mediaTemp.created = media.created
                        mediaTemp.sort_order = media.sort_order
                    }
                    this.mediaFiles.push(mediaTemp)
                })
            }
        },
        inputDocumentsFiles(data) {
            this.documentsError = ''
            if (!this.myIdea.documents) {
                this.myIdea.documents = []
            }
            let file = data
            file.name = ''
            this.myIdea.documents.push(file)
            this.$emit('inputDisabled', false)
        },
        async changeDocumentFileName(name, index) {
            let document = this.myIdea.documents[index]
            if (document.created) {
                this.isWorkingOnDocument = true
                await IdeasHelper.updateIdeaDocument({
                    id: document.temp_file_obj_id,
                    name: name,
                })
                    .then(() => {
                        this.myIdea.documents[index].name = name
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => {
                        this.isWorkingOnDocument = false
                    })
            } else {
                this.myIdea.documents[index].name = name
            }
        },
        async deleteDocumentFiles(val) {
            let document = this.myIdea.documents[val]
            if (this.isEditAprrovedPlannedOrImplemented && document.created) {
                this.isWorkingOnDocument = true
                await IdeasHelper.deleteIdeaDocument(document.temp_file_obj_id)
                    .then(() => {
                        this.myIdea.documents.splice(val, 1)
                        this.isWorkingOnDocument = false
                    })
                    .catch(err => {
                        this.isWorkingOnDocument = false
                        console.log(err)
                    })
            } else {
                this.myIdea.documents.splice(val, 1)
            }
        },
        setDocumentsFiles() {
            if (this.myIdea.documents) {
                this.myIdea.documents.forEach(doc => {
                    const document = {
                        filename: this.getFileName(doc.filename),
                        path: cloneDeep(doc.path),
                        name: doc.name ? doc.name : '',
                    }
                    if (doc.created) {
                        document.created = doc.created
                    }
                    this.documents.push(document)
                })
            }
        },
        changeFitler: debounce(function(search, event) {
            if (event) event.preventDefault()
            if (search) {
                UsersHelper.getZainers({
                    search: search,
                    limit: 999999999999,
                })
            } else {
                this.options = []
            }
        }, 300),
        async submit() {
            const isValid = await this.$refs.editOrResubmitForm.$refs.observer.validate()
            var submitBoolean = true
            this.ideaFormFields.fields.forEach(field => {
                if (field.type === 'dropdown') {
                    if (!this.myIdea.form_data[field.name] && field.required)
                        submitBoolean = false
                }
                if (field.type === 'multiselect' && field.required) {
                    if (
                        !this.myIdea.form_data[field.name] ||
                        !this.myIdea.form_data[field.name].length
                    )
                        submitBoolean = false
                }
            })
            if (
                (this.isEditAprrovedPlannedOrImplemented || submitBoolean) &&
                isValid
            ) {
                if (this.isFileSorted) {
                    let mediatemp = this.myIdea.medias
                    const updatePromises = []
                    for (const item of mediatemp) {
                        if (item.created) {
                            updatePromises.push(
                                IdeasHelper.updateIdeaMedia({
                                    id: item.temp_file_obj_id,
                                    sort_order: item.sort_order,
                                }).catch(error => {
                                    console.error(error)
                                }),
                            )
                        }
                    }
                    await Promise.all(updatePromises)
                }
                this.$emit('submit')
            } else {
                Notify.Failure(
                    this.$t('Please check the below form for errors'),
                )
            }
        },
        change(search) {
            this.changeFitler(search, null)
        },
        input() {
            let members = []
            this.selectedOptions.forEach(member => {
                members.push(member.id)
                this.selectedObjectOptions.push(member)
            })

            this.myIdea.members = cloneDeep(members)
        },
        deleteMember(value) {
            let object = this.selectedObjectOptions.find(
                option => option.id == value,
            )
            var index = this.selectedObjectOptions.indexOf(object)
            if (index !== -1) {
                this.selectedObjectOptions.splice(index, 1)
            }
            this.myIdea.members = this.myIdea.members.filter(member => {
                return member != value
            })
        },
        getOptions(val) {
            const values = val.split(',')
            const options = values.map(item => {
                return {
                    value: item.trim(),
                    text: item.trim(),
                }
            })
            return options
        },
        getMultipleDropDownOptions(val) {
            const values = val.split(',')
            const options = values.map(item => item.trim())
            return options
        },

        setBaseValue(value, name) {
            this.myIdea.form_data[name] = value
        },
        setCheckboxBaseValue(value, name) {
            if (value) this.myIdea.form_data[name] = value
            else this.myIdea.form_data[name] = ''
        },
        setMultipleCheckBoxValue(value, name) {
            const joinedValue = value.join(',')
            this.myIdea.form_data[name] = joinedValue.replace(/^,/, '')
        },
    },
    computed: {
        ...mapGetters(['userAuth', 'zainers', 'ideaObject', 'ideaFormFields']),
        dictionary() {
            return {
                en: {
                    messages: {
                        min: (_, { length }) =>
                            `Please enter atleast ${length} characters`,
                        specialCharactersNotAllowed: () =>
                            'Special characters not allowed',
                    },
                },
                ar: {
                    messages: {
                        min: (field, { length }) =>
                            `الرجاء إدخال ${length} ${
                                field === 'Description' ? 'حرف' : 'حرفا'
                            } على الأقل`,
                        specialCharactersNotAllowed: () =>
                            'الأحرف الخاصة غير مسموح بها',
                    },
                },
            }
        },
    },
    watch: {
        zainers() {
            let zainers = cloneDeep(this.zainers.results)
            zainers.forEach(option => {
                if (option.id != this.userAuth.id) {
                    option.photo = option.photo ? option.photo : memberImage
                    this.options.push(option)
                }
            })
        },
        '$i18n.locale': function() {
            localize(this.$i18n.locale, this.dictionary[this.$i18n.locale])
        },
    },
}
</script>

<style lang="scss" scoped>
.ideaDetail {
    &__head {
        &--status {
            margin-bottom: 2rem;
            font-size: 18px;
            padding: 0.5rem;
            width: 100%;
            background-color: var(--primary);
            color: var(--white);
            white-space: pre-wrap;
            div {
                padding: 0.5rem;
            }
            @media screen and (max-width: 575px) {
                margin-top: 85px;
            }
        }
    }
}
/deep/ .form-control {
    &::placeholder {
        color: var(--green) !important;
    }
}
/deep/ .v-select-toggle {
    &:focus,
    &:active {
        .arrow-down {
            filter: inherit;
        }
    }
    .arrow-down {
        margin-top: 0 !important;
    }
}
.uploadFiles {
    margin-left: auto !important;
    margin: auto;
    max-width: 600px;
    @media screen and (max-width: 575px) {
        margin-top: rem(80px);
    }
    /deep/.form-group {
        margin-bottom: 0rem;
    }
    /deep/.drop-zone {
        .dropzone {
            width: 100%;
            .dropzone-custom-title {
                color: var(--green);
            }
            .subtitle {
                color: var(--green);
                border-color: var(--green);
            }
        }
        /deep/.control-label {
            font-size: rem(22px);
            color: var(--primary);
            font-weight: 700;
            padding-left: rem(20px);
            margin-bottom: rem(12px);
        }
    }
    .submit-button-three {
        text-align: center;
        margin-top: rem(80px);
    }
    @media screen and (max-width: 767px) {
        .drop-zone {
            /deep/ {
                .control-label {
                    font-size: rem(18px);
                    padding-left: 0;
                }
            }
        }
    }
}
.checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
        cursor: pointer;
    }
    .control-label {
        padding-bottom: 0;
        @media screen and (max-width: 768px) {
            justify-content: center;
        }
    }
}
.disabled-checkbox-label {
    pointer-events: none;
    border-color: transparent;
    color: #999;
    background-color: #f4f4f4;
    opacity: 0.6;
}
@media screen and (min-width: 991.5px) {
    .button-row {
        margin-top: rem(80px);
    }
}
.ar .uploadFiles .drop-zone .control-label {
    padding-right: 1.25rem !important;
    padding-left: 0 !important;
}

@media screen and (max-width: 767px) {
    .ar .drop-zone.control-label {
        padding-right: 0 !important;
    }
}
</style>
