<template>
    <div>
        <base-form @submit="submit" ref="dynamicForm">
            <div v-for="(field, i) in ideaFormFields.fields" :key="i">
                <base-input
                    v-if="field.type === 'singleline'"
                    :label="field[`label_${$i18n.locale}`]"
                    :name="field.name"
                    :placeholder="field[`help_text_${$i18n.locale}`]"
                    :value="myIdea.form_data[field.name]"
                    v-model="myIdea.form_data[field.name]"
                    :required="field.required"
                />
                <base-textarea
                    v-if="field.type === 'multiline'"
                    :label="field[`label_${$i18n.locale}`]"
                    :name="field.name"
                    :placeholder="field[`help_text_${$i18n.locale}`]"
                    :value="myIdea.form_data[field.name]"
                    v-model="myIdea.form_data[field.name]"
                    :required="field.required"
                />
                <base-check-box
                    v-if="field.type === 'checkbox'"
                    :name="field.name"
                    :value="myIdea.form_data[field.name] ? true : false"
                    :options="getOptions(field[`label_${$i18n.locale}`])"
                    :required="field.required"
                    @input="setCheckboxBaseValue"
                    :type="field.type"
                ></base-check-box>

                <div v-if="field.type === 'checkboxes'" class="form-group">
                    <label class="control-label">
                        {{ field[`label_${$i18n.locale}`] }}
                        <span v-if="field.required">*</span>
                    </label>
                    <base-multiple-checkbox
                        :name="field.name"
                        :value="myIdea.form_data[field.name]"
                        :options="getOptions(field[`choices_${$i18n.locale}`])"
                        :required="field.required"
                        @input="setMultipleCheckBoxValue"
                        :type="field.type"
                    ></base-multiple-checkbox>
                </div>

                <div v-if="field.type === 'radio'" class="form-group">
                    <base-radio
                        :label="field[`label_${$i18n.locale}`]"
                        :name="field.name"
                        :value="myIdea.form_data[field.name]"
                        :options="getOptions(field[`choices_${$i18n.locale}`])"
                        :required="field.required"
                        @input="setBaseValue"
                    ></base-radio>
                </div>

                <div class="form-group " v-if="field.type === 'dropdown'">
                    <label class="control-label">
                        {{ field[`label_${$i18n.locale}`] }}
                        <span v-if="field.required">*</span>
                    </label>
                    <base-select
                        v-model="myIdea.form_data[field.name]"
                        :options="getOptions(field[`choices_${$i18n.locale}`])"
                        :required="field.required"
                    ></base-select>
                </div>

                <div class="form-group " v-if="field.type === 'multiselect'">
                    <label class="control-label">
                        {{ field[`label_${$i18n.locale}`] }}
                        <span v-if="field.required">*</span>
                    </label>
                    <base-multiple-drop-down
                        :name="field.name"
                        placeholder="Select Options"
                        :value="myIdea.form_data[field.name]"
                        :options="
                            getMultipleDropDownOptions(
                                field[`choices_${$i18n.locale}`],
                            )
                        "
                        :required="field.required"
                        @input="setBaseValue"
                    ></base-multiple-drop-down>
                </div>
            </div>
            <slot></slot>
        </base-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {},
    props: {
        myIdea: {
            type: Object,
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['ideaFormFields']),
    },
    mounted() {
        if (this.ideaFormFields.fields[0].type === 'multiline') {
            this.$refs.dynamicForm.$el.children[0].children[0]
                .getElementsByTagName('textarea')[0]
                .focus()
        } else if (this.ideaFormFields.fields[0].type === 'singleline') {
            this.$refs.dynamicForm.$el.children[0].children[0]
                .getElementsByTagName('input')[0]
                .focus()
        }
    },
    methods: {
        submit() {
            var submitBoolean = true
            this.ideaFormFields.fields.forEach(field => {
                if (field.type === 'dropdown') {
                    if (!this.myIdea.form_data[field.name] && field.required)
                        submitBoolean = false
                }
                if (field.type === 'multiselect' && field.required) {
                    if (
                        !this.myIdea.form_data[field.name] ||
                        !this.myIdea.form_data[field.name].length
                    )
                        submitBoolean = false
                }
            })
            if (submitBoolean) {
                this.$emit('submit')
            }
        },
        getOptions(val) {
            const values = val.split(',')
            const options = values.map(item => {
                return {
                    value: item.trim(),
                    text: item.trim(),
                }
            })
            return options
        },
        getMultipleDropDownOptions(val) {
            const values = val.split(',')
            const options = values.map(item => item.trim())
            return options
        },

        setBaseValue(value, name) {
            this.myIdea.form_data[name] = value
        },
        setCheckboxBaseValue(value, name) {
            if (value) this.myIdea.form_data[name] = value
            else this.myIdea.form_data[name] = ''
        },
        setMultipleCheckBoxValue(value, name) {
            const joinedValue = value.join(',')
            this.myIdea.form_data[name] = joinedValue.replace(/^,/, '')
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/ .form-control {
    &::placeholder {
        color: var(--green);
    }
}
.control-label {
    padding-bottom: 0;
    @media screen and (max-width: 768px) {
        justify-content: center;
    }
}
/deep/ .v-select-toggle {
    &:focus,
    &:active {
        .arrow-down {
            filter: inherit;
        }
    }
    .arrow-down {
        margin-top: 0 !important;
    }
}
</style>
