<template>
    <div>
        <!-- Banner -->
        <base-inner-banner :banner-image="pageBanner"></base-inner-banner>
        <!-- Banner -->

        <!-- Content wrapper -->
        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head :page-head="pagehead"></base-page-head>
                <!-- Page Head -->

                <div class="page-contents">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div
                            class="right-col"
                            :class="{ 'no-steps': isIdeaEditOrResubmit }"
                        >
                            <steps
                                v-if="!isIdeaEditOrResubmit"
                                :step="step"
                                :tabs="tabs"
                            ></steps>
                            <component
                                v-if="
                                    hasAllFieldsPopulated &&
                                        myIdea.country &&
                                        bucketCategoriesList &&
                                        bucketCategoriesList.results
                                "
                                :is="
                                    isIdeaEditOrResubmit
                                        ? 'EditOrResubmit'
                                        : currentTabComponent
                                "
                                :myIdea="myIdea"
                                :countries="countries"
                                :selectedObjectOptions="selectedObjectOptions"
                                :isEditAprrovedPlannedOrImplemented="
                                    disableNonEditableFields
                                "
                                @submit="submit"
                                @inputDisabled="setDisabled"
                                data-aos="fade-up"
                                data-aos-delay="1000"
                                data-aos-easing="ease"
                                data-aos-duration="1000"
                            >
                                <submit-idea-footer
                                    :step="step"
                                    :tabs="tabs"
                                    :disabled="disabled"
                                    @back="back"
                                    :isIdeaEditOrResubmit="isIdeaEditOrResubmit"
                                />
                            </component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- SubmitIdea popup -->
        <b-modal
            id="submitIdea"
            ref="submitIdea"
            hide-footer
            @hidden="toggleModal()"
        >
            <div class="submitIdea">
                <div
                    v-if="
                        dashboardSettings &&
                            dashboardSettings.dashboard_settings
                    "
                    class="submitIdea__title"
                    v-html="getPopupText('title')"
                ></div>
                <div
                    v-if="
                        dashboardSettings &&
                            dashboardSettings.dashboard_settings
                    "
                    class="submitIdea__sub-title"
                    v-html="getPopupText('sub_title')"
                ></div>
                <div class="submitIdea__image">
                    <img
                        loading="lazy"
                        class="img-fluid"
                        src="@/assets/images/submitidea-img.png"
                        alt=""
                    />
                    <span
                        ><img
                            loading="lazy"
                            src="@/assets/images/svg/pattern.svg"
                            alt=""
                    /></span>
                </div>
                <div class="inline-buttons">
                    <a
                        v-if="!isIdeaEditOrResubmit"
                        class="btn btn-pink"
                        :href="$router.resolve({ name: 'submit-idea' }).href"
                    >
                        {{ $t('Add another Idea') }}
                    </a>
                    <router-link
                        v-else-if="
                            ideaObject && ideaObject.state === 'resubmit'
                        "
                        :to="{
                            name: 'user-ideas',
                        }"
                        class="btn btn-pink"
                    >
                        {{ $t('View My Ideas') }}
                    </router-link>
                    <a
                        v-if="
                            ideaObject &&
                                (ideaObject.state === 'approved' ||
                                    ideaObject.state === 'planned' ||
                                    ideaObject.state === 'implemented')
                        "
                        class="btn btn-primary"
                        href="javascript:void(0)"
                        @click="$refs.submitIdea.hide()"
                    >
                        {{ $t('Close') }}
                    </a>
                    <a
                        v-else-if="
                            ideaObject && ideaObject.state === 'resubmit'
                        "
                        @click="redirectToCMS()"
                        class="btn btn-primary"
                    >
                        {{ $t('Go to homepage') }}
                    </a>
                    <router-link
                        v-else
                        :to="{
                            name: 'user-ideas',
                        }"
                        class="btn btn-primary"
                    >
                        {{ $t('Go to homepage') }}
                    </router-link>
                </div>
            </div>
        </b-modal>
        <!-- SubmitIdea popup -->
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/submit-idea-banner.jpg'
import imgLarge from '@/assets/images/banners/submit-idea-banner.jpg'
import imgBig from '@/assets/images/banners/submit-idea-banner.jpg'
import { ActivitiesHelper } from '@/common/crud-helpers/activities'
import { IdeasHelper } from '@/common/crud-helpers/ideas'
import { API_BASE } from '@/conf'
import EditOrResubmit from '@/modules/ideas/components/submit/EditOrResubmit.vue'
import Step1 from '@/modules/ideas/components/submit/Step1'
import Step2 from '@/modules/ideas/components/submit/Step2'
import Step3 from '@/modules/ideas/components/submit/Step3'
import Steps from '@/modules/ideas/components/submit/Steps'
import SubmitIdeaFooter from '@/modules/ideas/components/submit/SubmitIdeaFooter'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        Steps,
        Step1,
        Step2,
        Step3,
        SubmitIdeaFooter,
        EditOrResubmit,
    },
    data: function() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
                imgAlt: this.$t('My ideas'),
            },
            pagehead: {
                title: this.$t('My ideas'),
                subtitle: this.$t('Submit Your Idea'),
                shortdes: this.$t(
                    `We love turning ideas into reality. We're always on the lookout for the next big idea, tell us yours…`,
                ),
            },
            step: 0,
            currentTabComponent: 'step1',
            tabs: [
                {
                    component: 'step1',
                    title: this.$t('Campaign selection'),
                },
                {
                    component: 'step2',
                    title: this.$t('Idea brief'),
                },
                {
                    component: 'step3',
                    title: this.$t('Idea details'),
                },
            ],
            myIdea: {
                title: '',
                description: '',
                members: [],
                documents: [],
                // preview_image: '',
                // media_files: [],
                category: {},
                invovle_in_implementation: false,
                opco: '',
                selected_bucket: '',
                country: '',
                form_data: {},
            },
            disableNonEditableFields: false,
            uuId: '',
            disabled: false,
            selectedObjectOptions: [],
            isIdeaEditOrResubmit: this.$route.params.uuid ? true : false,
            hasAllFieldsPopulated: this.$route.params.uuid ? false : true,
            countries: [
                {
                    value: '@zain.com',
                    en_text: 'Zain Group',
                    text: this.$t('Zain Group'),
                },
                // { value: '@kuwaitnet.com', en_text: 'Zain Group', text: this.$t('Zain Group') },
                {
                    value: '@bh.zain.com',
                    en_text: 'Bahrain',
                    text: this.$t('Bahrain'),
                },
                {
                    value: '@iq.zain.com',
                    en_text: 'Iraq',
                    text: this.$t('Iraq'),
                },
                {
                    value: '@jo.zain.com',
                    en_text: 'Jordan',
                    text: this.$t('Jordan'),
                },
                { value: '@sa.zain.com', en_text: 'KSA', text: this.$t('KSA') },
                {
                    value: '@kw.zain.com',
                    en_text: 'Kuwait',
                    text: this.$t('Kuwait'),
                },
                {
                    value: '@omantel.om',
                    en_text: 'Omantel',
                    text: this.$t('Omantel'),
                },
                {
                    value: '@ss.zain.com',
                    en_text: 'South Sudan',
                    text: this.$t('South Sudan'),
                },
                {
                    value: '@sd.zain.com',
                    en_text: 'Sudan',
                    text: this.$t('Sudan'),
                },
                {
                    value: '@zc.zain.com',
                    en_text: 'Zain Cash',
                    text: this.$t('Zain Cash'),
                },
                {
                    value: '@zaintech.com',
                    en_text: 'ZainTech',
                    text: this.$t('ZainTech'),
                },
            ],
        }
    },
    async created() {
        await IdeasHelper.getBucketCategoriesList()
        IdeasHelper.getIdeaFormFields()
        if (this.isIdeaEditOrResubmit) {
            await IdeasHelper.get(this.$route.params.uuid)
                .then(() => {
                    this.setIdeaValues()
                })
                .catch(err => {
                    console.log(err)
                })
        }
        if (this.userAuth && this.userAuth.email) {
            this.selectUserOpco()
        }
    },
    methods: {
        setDisabled(disabled) {
            this.disabled = disabled
        },
        back() {
            this.step--
            this.currentTabComponent = this.tabs[this.step].component
        },
        setIdeaValues() {
            if (
                ![
                    'resubmit',
                    'submitted',
                    'approved',
                    'planned',
                    'implemented',
                ].includes(this.ideaObject.state)
            ) {
                this.$router.push({
                    name: 'user-ideas',
                })
            } else if (this.ideaObject.state === 'resubmit') {
                this.myIdea.parent_idea_uuid = this.ideaObject.uuid
            } else if (this.ideaObject.state !== 'submitted') {
                this.$refs.submitIdea.show()
                this.disableNonEditableFields = true
                this.myIdea.medias = []
                if (this.ideaObject?.medias?.length) {
                    let sortedArray = this.ideaObject.medias.slice()
                    sortedArray.sort((a, b) => {
                        if (a.sort_order === null && b.sort_order !== null) {
                            return 1
                        } else if (
                            a.sort_order !== null &&
                            b.sort_order === null
                        ) {
                            return -1
                        } else {
                            return a.sort_order - b.sort_order
                        }
                    })
                    sortedArray.forEach((item, index) => {
                        this.myIdea.medias.push({
                            created: item.created,
                            filename: this.getFileName(item.file),
                            name: item.name,
                            path: item.file,
                            temp_file_obj_id: item.id,
                            sort_order: index,
                        })
                    })
                }
            }
            this.step = 2
            this.myIdea.category.bucket = this.ideaObject?.category?.bucket_display
            this.myIdea.category.category_id = this.ideaObject?.category.id
            this.myIdea.category.country_display = this.ideaObject?.country
            this.myIdea.category.description = this.ideaObject?.category?.description
            this.myIdea.category.title = this.ideaObject?.category?.title
            this.myIdea.category.zainers_invloved = this.ideaObject?.category?.zainers_invloved
            this.myIdea.title = this.ideaObject?.title
            this.myIdea.description = this.ideaObject?.description
            this.myIdea.invovle_in_implementation = this.ideaObject?.invovle_in_implementation
            this.myIdea.uuid = this.$route?.params?.uuid
            this.ideaObject.documents.forEach(item => {
                this.myIdea.documents.push({
                    created: item.created,
                    filename: this.getFileName(item.document),
                    name: item.name,
                    path: item.document,
                    temp_file_obj_id: item.id,
                })
            })
            this.ideaObject.members.forEach(item => {
                this.selectedObjectOptions.push(item.user_detail)
                this.myIdea.members.push(item.member)
            })
            this.ideaObject.form_values.forEach(item => {
                this.$set(this.myIdea.form_data, item.name, item.value)
            })
            this.hasAllFieldsPopulated = true
        },
        submit() {
            if (this.step == this.tabs.length - 1) {
                this.disabled = true
                let sendIdea = cloneDeep(this.myIdea)
                if (this.isIdeaEditOrResubmit) {
                    if (sendIdea?.documents?.length) {
                        if (this.ideaObject?.state === 'resubmit') {
                            let oldDocumentIds = sendIdea.documents
                                .filter(doc => doc.created)
                                .map(doc => String(doc.temp_file_obj_id))
                            if (oldDocumentIds.length) {
                                sendIdea.parent_document_ids = oldDocumentIds
                            }
                        }
                        let documentTemp = sendIdea.documents.filter(
                            doc => !doc.created,
                        )
                        sendIdea.documents = documentTemp
                    }
                    if (sendIdea?.medias?.length) {
                        let mediaTemp = sendIdea.medias.filter(
                            media => !media.created,
                        )
                        sendIdea.medias = mediaTemp
                    }
                }
                if (
                    this.isIdeaEditOrResubmit &&
                    this.ideaObject?.state !== 'resubmit'
                ) {
                    this.myIdea.uuid = this.uuid(this.myIdea.uuid)
                    delete sendIdea.opco
                    delete sendIdea.selected_buket
                    sendIdea.owner_id = this.userAuth.id
                    sendIdea.category_id = sendIdea.category.category_id
                    IdeasHelper.put(sendIdea)
                        .then(resp => {
                            this.uuId = resp.data.uuid
                            this.$router.push({
                                name: 'user-idea-details',
                                params: {
                                    uuid: resp?.data?.uuid,
                                },
                                query: {
                                    state:
                                        this.ideaObject?.state === 'submitted'
                                            ? 'ideaEdited'
                                            : sendIdea?.medias?.length
                                            ? 'imageAdded'
                                            : undefined,
                                },
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            this.disabled = false
                        })
                } else {
                    delete sendIdea.opco
                    delete sendIdea.selected_bucket
                    delete sendIdea.uuid
                    sendIdea.category_id = sendIdea.category.category_id
                    IdeasHelper.create(sendIdea)
                        .then(resp => {
                            this.uuId = resp.data.uuid
                            this.$refs.submitIdea.show()
                        })
                        .catch(err => {
                            console.log(err)
                            this.disabled = false
                        })
                }
            } else {
                this.step++
                this.currentTabComponent = this.tabs[this.step].component
            }
        },
        toggleModal() {
            if (
                this.isIdeaEditOrResubmit &&
                (this.ideaObject?.state === 'approved' ||
                    this.ideaObject?.state === 'planned' ||
                    this.ideaObject?.state === 'implemented')
            ) {
                return
            }
            this.$router.push({
                name: 'user-ideas',
            })
        },
        selectUserOpco() {
            let opcoTemp = ''
            if (this.ideaObject && this.ideaObject.id) {
                opcoTemp = this.ideaObject.country
            } else {
                opcoTemp = this.userAuth.country
            }
            this.myIdea.opco = this.countries.find(
                country => country.en_text === opcoTemp,
            )
                ? this.countries.find(country => country.en_text === opcoTemp)
                      .value
                : this.countries[0].value
        },
        getPopupText(type) {
            if (!this.isIdeaEditOrResubmit) {
                return this.dashboardSettings.dashboard_settings[
                    `submit_idea_${type}_${this.$i18n.locale}`
                ]
            } else if (this.ideaObject?.state === 'resubmit') {
                return this.dashboardSettings.dashboard_settings[
                    `resubmit_idea_${type}_${this.$i18n.locale}`
                ]
            } else if (
                this.ideaObject?.state === 'approved' ||
                this.ideaObject?.state === 'planned' ||
                this.ideaObject?.state === 'implemented'
            ) {
                return this.dashboardSettings.dashboard_settings[
                    `edited_idea_${type}_${this.$i18n.locale}`
                ].replace(
                    'idea_state_placeholder',
                    this.$t(this.ideaObject?.state),
                )
            }
        },
        redirectToCMS() {
            let redirectUrl = API_BASE + '/' + this.$i18n.locale + '/'
            if (localStorage.getItem('token')) {
                ActivitiesHelper.cmsRedirectUrl({
                    API_BASE: API_BASE,
                    lang: this.$i18n.locale,
                    url: redirectUrl,
                })
                    .then(resp => {
                        window.location.href = JSON.parse(
                            resp.request.response,
                        ).url
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                window.location.href = redirectUrl
            }
        },
    },
    computed: {
        ...mapGetters([
            'ideaObject',
            'userAuth',
            'dashboardSettings',
            'bucketCategoriesList',
            'ideaFormFields',
            'userAuth',
        ]),
        selectedCountry() {
            return this.myIdea.opco.length > 0
                ? this.countries.find(
                      country => country.value === this.myIdea.opco,
                  ).en_text
                : ''
        },
    },
    watch: {
        userAuth() {
            if (
                this.userAuth &&
                this.userAuth.email &&
                this.myIdea.opco === ''
            ) {
                this.selectUserOpco()
            }
        },
        'myIdea.opco'() {
            this.myIdea.country = this.selectedCountry
        },
        ideaFormFields() {
            if (
                this.tabs.length === 3 &&
                this.ideaFormFields.fields &&
                !this.ideaFormFields.fields.length
            ) {
                this.tabs.pop()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
// SubmitIdea popup css
.content-wrapper {
    /deep/.page__head {
        min-height: auto;
        &--title {
            text-transform: uppercase;
        }
        &--subtitle {
            text-transform: uppercase;
            color: var(--primary);
        }
    }
    .page-contents {
        .no-steps {
            padding-top: 150px !important;
            @media screen and (max-width: 575px) {
                padding-top: 240px !important;
            }
        }
        /deep/ .sidenav-wrapper .right-col {
            padding-top: 0px;
            .steps {
                &::after {
                    background: var(--secondary);
                    height: 4px;
                }
            }
        }
    }
}
.submitIdea {
    max-width: 720px;
    margin: auto;
    text-align: center;
    &__title {
        /deep/ p {
            font-size: rem(52px);
            font-weight: 900;
            color: #009b74;
            text-align: center;
            margin-bottom: rem(50px);
            text-transform: uppercase;
            @media screen and (max-width: 767px) {
                font-size: rem(38px);
            }
            @media screen and (max-width: 575px) {
                font-size: rem(30px);
            }
        }
    }
    &__sub-title {
        /deep/ p {
            font-size: rem(24px);
            color: #333333;
            line-height: 1.4;
        }
    }
    &__image {
        position: relative;
        @media screen and (max-width: 767px) {
            padding: rem(25px) 0;
        }
        span {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
                max-width: 220px;
                transform: rotate(-80deg) translateX(50%);
                @media screen and (max-width: 767px) {
                    max-width: 38%;
                }
            }
        }
    }
    p {
        font-size: rem(24px);
        color: #333333;
        line-height: 1.4;
    }
    .inline-buttons {
        padding-top: rem(55px);
        a {
            margin-left: rem(20px);
            margin-right: rem(20px);
            padding: 12px 23px;
            font-size: 18px !important;
        }
        .btn-pink {
            font-size: 1.5rem;
        }
        .btn-primary {
            border: solid 2px transparent;
            &:hover {
                background: transparent;
                border-color: var(--primary);
                color: var(--primary);
            }
        }
        @media screen and (max-width: 575px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            a {
                font-size: 16px !important;
                &:not(:last-child) {
                    margin: 0;
                    margin-bottom: rem(15px);
                }
            }
        }
    }
}
/deep/.form-grop {
    margin-bottom: rem(50px);
}
.accNavClose {
    .sidenav-wrapper {
        .right-col {
            padding-bottom: rem(120px);
        }
    }
}
/deep/ {
    .sidenav-wrapper {
        .right-col {
            padding-top: 7px;
            @media screen and (min-width: 1400px) {
                padding-top: 25px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    /deep/ {
        .page-banner {
            --h: 445px;
        }
    }
}
@media screen and (max-width: 575px) {
    /deep/ {
        .page-banner {
            --h: 600px;
        }
        .sidenav-wrapper {
            .right-col {
                form {
                    padding-top: rem(75px);
                }
            }
        }
    }
}
</style>
