<template>
    <base-form @submit="submit" id="step-1">
        <div class="form-group ">
            <label class="control-label">{{ $t('Select the Opco') }}*</label>
            <base-select
                v-model="myIdea.opco"
                :options="countries"
                @input="changeOPCO"
                :required="true"
            ></base-select>
        </div>
        <submit-idea-category
            v-if="myIdea.opco"
            :myIdea="myIdea"
            :categoryError="categoryError"
            :countries="countries"
        />
        <!-- <base-input
            :label="$t('Idea title')"
            name="title"
            :placeholder="$t('Please Enter Idea title')"
            maxlength="100"
            :infolabel="`100 ${$t('characters')}`"
            :value="myIdea.title"
            v-model="myIdea.title"
            :required="true"
        />
        <base-textarea
            :label="$t('About you')"
            name="about_you"
            :placeholder="$t('Please Enter About you')"
            maxlength="100"
            :infolabel="`100 ${$t('characters')}`"
            :value="myIdea.about_you"
            v-model="myIdea.about_you"
            :required="true"
        />
        <base-textarea
            :label="$t('Idea description')"
            name="description"
            :placeholder="$t('Please Enter Idea Description')"
            maxlength="2000"
            :infolabel="`2000 ${$t('characters')}`"
            :value="myIdea.description"
            v-model="myIdea.description"
            :required="true"
        />
        <base-input
            :label="$t('What is your Idea about?')"
            name="about_idea"
            :placeholder="$t('Please Enter about your idea')"
            maxlength="255"
            :value="myIdea.about_idea"
            v-model="myIdea.about_idea"
            :required="true"
        />
        <base-input
            :label="$t('Why are you the right person to implement this idea?')"
            name="why_you"
            :placeholder="$t('Please Enter the text')"
            maxlength="255"
            :value="myIdea.why_you"
            v-model="myIdea.why_you"
            :required="true"
        />
        <base-input
            :label="$t('What makes your Idea unique?')"
            name="why_idea_unique"
            :placeholder="$t('Please Enter the text')"
            maxlength="255"
            :value="myIdea.why_idea_unique"
            v-model="myIdea.why_idea_unique"
            :required="true"
        />
        <base-input
            :label="$t('Whats the inspiration behind the Idea?')"
            name="idea_inspiration"
            :placeholder="$t('Please Enter the text')"
            maxlength="255"
            :value="myIdea.idea_inspiration"
            v-model="myIdea.idea_inspiration"
            :required="true"
        /> -->
        <!-- <base-input
            :label="$t('Whats the inspiration behind the Idea?')"
            name="get_state_display"
            :placeholder="$t('Please Enter the text')"
            maxlength="255"
            :value="myIdea.get_state_display"
            v-model="myIdea.get_state_display"
            :required="true"
        /> -->
        <slot></slot>
    </base-form>
</template>

<script>
import SubmitIdeaCategory from '@/modules/ideas/components/submit/SubmitIdeaCategory'

export default {
    props: {
        myIdea: {
            type: Object,
        },
        countries: {
            type: Array,
            required: true,
        },
    },
    components: { SubmitIdeaCategory },
    data() {
        return {
            categoryError: '',
        }
    },
    methods: {
        submit() {
            if (!this.myIdea.category.category_id) {
                this.categoryError = this.$t('This field is required')
                return
            }
            this.$emit('submit')
        },
        changeOPCO() {
            // console.log(this.myIdea.opco, val)
        },
    },
    watch: {
        'myIdea.category.title'() {
            this.categoryError = ''
        },
    },
}
</script>

<style lang="scss" scoped>
#step-1 {
    /deep/.v-select {
        .v-select-toggle {
            &:focus,
            &:active {
                color: var(--white) !important;
            }
            .arrow-down {
                margin-top: 0 !important;
            }
            // .active {
            //     color: var(--white) !important;
            // }
        }
        .v-dropdown-container {
            ul {
                max-height: fit-content !important;
            }
        }
    }
}
</style>
