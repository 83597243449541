<template>
    <base-form ref="step2Form" @submit="submit">
        <base-input
            ref="ideaTitle"
            :label="$t('Idea title')"
            name="Title"
            :placeholder="$t('Please enter the idea title')"
            :value="myIdea.title"
            v-model="myIdea.title"
            rules="specialCharactersNotAllowed"
            :required="true"
        />
        <!-- minlength="25"
            maxlength="250"
            :infolabel="`25 ${$t('characters')}`"
            rules="min:25" -->
        <base-textarea
            :label="$t('Give a detailed example of how this idea would work')"
            name="Description"
            :placeholder="
                $t('Explain the idea in detail; elaborate as much as you can')
            "
            :value="myIdea.description"
            v-model="myIdea.description"
            :required="true"
        />
        <!-- minlength="100"
            maxlength="2000"
            rules="min:100"
            :infolabel="`100 ${$t('characterss')}`"
        -->
        <div
            v-if="myIdea.category.zainers_invloved"
            class="form-group checkbox"
        >
            <input
                type="checkbox"
                name="radio"
                v-model="myIdea.invovle_in_implementation"
            />
            <label
                class="control-label"
                @click="
                    myIdea.invovle_in_implementation = !myIdea.invovle_in_implementation
                "
            >
                {{
                    $t('I would like to be involved in the idea implementation')
                }}
            </label>
        </div>
        <div class="uploadFiles">
            <!-- <base-drop-zone
                :label="$t('Upload your promotional media files')"
                :dropzonesettings="{
                    acceptedFiles: '.jpg,.jpeg,.png,.mp4,.webm',
                }"
                name="media_files"
                :existFiles="mediaFiles"
                :error="mediaFilesError"
                :show-change-name="true"
                @input="inputMediaFiles"
                @fileAdde="fileAdde"
                @deleteInput="deleteMediaFiles"
                @changeFileName="changeFileName"
            ></base-drop-zone> -->
            <!-- :dropzonesettings="{
                    acceptedFiles:
                        '.jpg,.jpeg,.png,.mp4,.webm,.pdf,.xls,.xlsx,.csv',
                }" -->
            <base-drop-zone
                :label="$t('Upload supporting documents and images')"
                name="documents"
                :existFiles="documents"
                :error="documentsError"
                :show-change-name="true"
                @input="inputDocumentsFiles"
                @fileAdde="fileAdde"
                @deleteInput="deleteDocumentFiles"
                @changeFileName="changeDocumentFileName"
            ></base-drop-zone>
        </div>
        <base-multiple-select
            :label="$t('Add your team members')"
            :labelIcon="labelIcon"
            name="members"
            :placeholder="$t('Add your team members')"
            :value="selectedOptions"
            v-model="selectedOptions"
            :required="false"
            :options="options"
            options_value_key="id"
            :fields="['photo', 'display_name', 'email', 'country']"
            @change="change"
            @input="input"
            @deleteMember="deleteMember"
        />
        <slot></slot>
    </base-form>
</template>

<script>
import memberImage from '@/assets/images/svg/person.svg'
import userIcon from '@/assets/images/svg/user-icon-2.svg'
import { UsersHelper } from '@/common/crud-helpers/users'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { Notify } from 'notiflix'
import { localize } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
    props: {
        myIdea: {
            type: Object,
        },
        selectedObjectOptions: {
            type: Array,
        },
    },
    created() {
        this.labelIcon = userIcon
        this.selectedOptions = cloneDeep(this.selectedObjectOptions)
        this.selectedOptions.forEach(option => {
            option.photo = option.photo ? option.photo : memberImage
        })
        // this.setMediaFiles()
        this.setDocumentsFiles()
    },
    mounted() {
        localize(this.$i18n.locale, this.dictionary[this.$i18n.locale])
        this.$refs.ideaTitle.$el.getElementsByTagName('input')[0].focus()
    },
    data() {
        return {
            selectedOptions: [],
            options: [],
            members: [],
            // mediaFiles: [],
            // mediaFilesError: '',
            documents: [],
            documentsError: '',
        }
    },
    methods: {
        // inputMediaFiles(data) {
        //     this.mediaFilesError = ''
        //     if (!this.myIdea.media_files) {
        //         this.myIdea.media_files = []
        //     }
        //     let file = data
        //     file.name = ''
        //     this.myIdea.media_files.push(file)
        //     this.$emit('inputDisabled', false)
        // },
        fileAdde() {
            this.$emit('inputDisabled', true)
        },
        // changeFileName(name, index) {
        //     this.myIdea.media_files[index].name = name
        // },
        // deleteMediaFiles(val) {
        //     this.myIdea.media_files.splice(val, 1)
        // },
        // setMediaFiles() {
        //     if (this.myIdea.media_files) {
        //         this.myIdea.media_files.forEach(media => {
        //             this.mediaFiles.push({
        //                 filename: this.getFileName(media.filename),
        //                 path: cloneDeep(media.path),
        //                 name: media.name ? media.name : '',
        //             })
        //         })
        //     }
        // },
        inputDocumentsFiles(data) {
            this.documentsError = ''
            if (!this.myIdea.documents) {
                this.myIdea.documents = []
            }
            let file = data
            file.name = ''
            this.myIdea.documents.push(file)
            this.$emit('inputDisabled', false)
        },
        changeDocumentFileName(name, index) {
            this.myIdea.documents[index].name = name
        },
        deleteDocumentFiles(val) {
            this.myIdea.documents.splice(val, 1)
        },
        setDocumentsFiles() {
            if (this.myIdea.documents) {
                this.myIdea.documents.forEach(doc => {
                    this.documents.push({
                        filename: this.getFileName(doc.filename),
                        path: cloneDeep(doc.path),
                        name: doc.name ? doc.name : '',
                    })
                })
            }
        },
        changeFitler: debounce(function(search, event) {
            if (event) event.preventDefault()
            if (search) {
                UsersHelper.getZainers({
                    search: search,
                    limit: 999999999999,
                })
            } else {
                this.options = []
            }
        }, 300),
        async submit() {
            const isValid = await this.$refs.step2Form.$refs.observer.validate()
            if (isValid) {
                this.$emit('submit')
            } else {
                Notify.Failure(
                    this.$t('Please check the below form for errors'),
                )
            }
        },
        change(search) {
            this.changeFitler(search, null)
        },
        input() {
            let members = []
            this.selectedOptions.forEach(member => {
                members.push(member.id)
                this.selectedObjectOptions.push(member)
            })

            this.myIdea.members = cloneDeep(members)
        },
        deleteMember(value) {
            // use deleteOption
            let object = this.selectedObjectOptions.find(
                option => option.id == value,
            )
            var index = this.selectedObjectOptions.indexOf(object)
            if (index !== -1) {
                this.selectedObjectOptions.splice(index, 1)
            }
            // use deleteOption/
            this.myIdea.members = this.myIdea.members.filter(member => {
                return member != value
            })
        },
    },
    computed: {
        ...mapGetters(['userAuth', 'zainers', 'ideaObject']),
        dictionary() {
            return {
                en: {
                    messages: {
                        min: (_, { length }) =>
                            `Please enter atleast ${length} characters`,
                        specialCharactersNotAllowed: () =>
                            'Special characters not allowed',
                    },
                },
                ar: {
                    messages: {
                        min: (field, { length }) =>
                            `الرجاء إدخال ${length} ${
                                field === 'Description' ? 'حرف' : 'حرفا'
                            } على الأقل`,
                        specialCharactersNotAllowed: () =>
                            'الأحرف الخاصة غير مسموح بها',
                    },
                },
            }
        },
    },
    watch: {
        zainers() {
            let zainers = cloneDeep(this.zainers.results)
            zainers.forEach(option => {
                if (option.id != this.userAuth.id) {
                    option.photo = option.photo ? option.photo : memberImage
                    this.options.push(option)
                }
            })
        },
        '$i18n.locale': function() {
            localize(this.$i18n.locale, this.dictionary[this.$i18n.locale])
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/ .form-control {
    &::placeholder {
        color: var(--green) !important;
    }
}
.uploadFiles {
    margin-left: auto !important;
    margin: auto;
    max-width: 600px;
    @media screen and (max-width: 575px) {
        margin-top: rem(80px);
    }
    /deep/.form-group {
        margin-bottom: 0rem;
    }
    /deep/.drop-zone {
        .dropzone {
            width: 100%;
            .dropzone-custom-title {
                color: var(--green);
            }
            .subtitle {
                color: var(--green);
                border-color: var(--green);
            }
        }
        /deep/.control-label {
            font-size: rem(22px);
            color: var(--primary);
            font-weight: 700;
            padding-left: rem(20px);
            margin-bottom: rem(12px);
        }
    }
    .submit-button-three {
        text-align: center;
        margin-top: rem(80px);
    }
    @media screen and (max-width: 767px) {
        .drop-zone {
            /deep/ {
                .control-label {
                    font-size: rem(18px);
                    padding-left: 0;
                }
            }
        }
    }
}
.checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
        cursor: pointer;
    }
    .control-label {
        padding-bottom: 0;
        @media screen and (max-width: 768px) {
            justify-content: center;
        }
    }
}
@media screen and (min-width: 991.5px) {
    .button-row {
        margin-top: rem(80px);
    }
}
.ar .uploadFiles .drop-zone .control-label {
    padding-right: 1.25rem !important;
    padding-left: 0 !important;
}

@media screen and (max-width: 767px) {
    .ar .drop-zone.control-label {
        padding-right: 0 !important;
    }
}
</style>
