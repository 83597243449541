<template>
    <div class="button-row justify-content-center" v-if="!isIdeaEditOrResubmit">
        <b-button
            v-if="step != 0"
            type="button"
            variant="primary"
            @click="$emit('back', $event)"
            class="back"
            >{{ $t('Back') }}</b-button
        >
        <b-button
            v-if="step < tabs.length - 1"
            :disabled="disabled"
            type="submit"
            variant="primary"
            class="next"
            >{{ $t('Next') }}</b-button
        >
        <b-button
            v-else
            type="submit"
            class="submit"
            :disabled="disabled"
            variant="primary"
            >{{ $t('Submit') }}</b-button
        >
    </div>
    <div class="button-row justify-content-center" v-else>
        <b-button
            type="submit"
            class="submit"
            :disabled="disabled"
            variant="primary"
            >{{ $t('Submit') }}</b-button
        >
    </div>
</template>

<script>
export default {
    props: {
        step: Number,
        tabs: {
            type: Array,
        },
        disabled: Boolean,
        isIdeaEditOrResubmit: {
            type: Boolean,
            default: false,
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.back {
    margin-right: 25px;
}
@media screen and (max-width: 575px) {
    .button-row {
        flex-direction: column;
        .btn {
            &.back {
                margin: 0 0 rem(16px) 0 !important;
            }
        }
    }
}
.ar {
    .back {
        margin-right: 0px;
        margin-left: 25px;
    }
    .next {
        margin-right: 25px;
    }
    // .submit {
    //     margin-right: 25px;
    // }
}
</style>
