<template>
    <div>
        <div class="steps">
            <ul>
                <li
                    v-for="(tab, i) in tabs"
                    :key="i"
                    :class="[
                        i < step ? 'completed' : '',
                        i == step ? 'current' : '',
                    ]"
                >
                    <span>{{ i + 1 }}</span>
                    {{ tab.title }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        step: Number,
        tabs: {
            type: Array,
        },
    },
}
</script>

<style lang="scss">
.steps {
    margin-bottom: rem(100px);
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: var(--aqua);
        left: 44%;
        bottom: -35px;
        transform: translateX(-50%);
        z-index: -1;
        @media screen and (max-width: 1500px) {
            bottom: -61px;
        }
        @media screen and (max-width: 991px) {
            bottom: -16px;
            width: 102%;
        }
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    ul {
        @include flex(center, space-between);
        list-style: none;
        li {
            @include flex(center, center);
            flex-direction: column;
            color: #939393;
            font-size: rem(20px);
            font-weight: 700;
            text-transform: uppercase;
            position: relative;
            width: 33%;
            text-align: center;
            line-height: 1.2;
            min-height: 78px;
            @media screen and (min-width: 992px) and (max-width: 1100px) {
                font-size: 19px !important;
            }
            @media screen and (max-width: 767px) {
                font-size: 13px !important;
            }
            &:not(:last-child) {
                &:after {
                    content: '';
                    position: absolute;
                    width: 70%;
                    height: 1px;
                    border: 1px dashed #939393;
                    top: 20px;
                    right: -70%;
                    transform: translateX(-50%);
                }
            }
            span {
                width: 28px;
                height: 28px;
                border-radius: 100%;
                background: #939393;
                font-size: rem(16px);
                color: var(--default);
                font-weight: 700;
                @include flex(center, center);
                margin-bottom: rem(15px);
                padding-top: 3px;
                position: relative;
            }
            &.completed,
            &.current {
                color: var(--white);
                font-size: rem(24px);
                span {
                    background: var(--primary) !important;
                    width: 40px;
                    height: 40px;
                    font-size: rem(26px);
                }
            }
            &.completed {
                span {
                    font-size: 0 !important;
                    background: var(--aqua);
                    &:before {
                        position: absolute;
                        content: '';
                        background-image: url("data:image/svg+xml, %3Csvg fill='%23000' height='417pt' viewBox='0 -46 417.81333 417' width='417pt' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0'/%3E%3C/svg%3E");
                        width: 22px;
                        height: 21px;
                        background-repeat: no-repeat;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        background-size: cover;
                    }
                }
            }
            &.current {
                span {
                    background: var(--aqua);
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        ul {
            li {
                &.completed,
                &.current {
                    span {
                        width: 35px;
                        height: 35px;
                        font-size: rem(24px);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 575px) {
        max-width: 200px;
        margin: auto;
        ul {
            flex-direction: column;
            li {
                flex-direction: row !important;
                justify-content: start;
                flex-wrap: nowrap;
                width: 100%;
                &:not(:last-child) {
                    &:after {
                        width: 2px;
                        height: 32px;
                        right: auto;
                        left: 16px;
                        top: auto;
                        bottom: -20px;
                    }
                }
                span {
                    margin: 0 rem(16px) 0 0;
                }
            }
        }
    }
}

// ar style

.ar {
    .steps {
        &:after {
            right: 44%;
            left: auto;
            transform: translateX(50%);
            // bottom: -38px;
            // @media screen and (max-width: 1400px) {
            //     bottom: -24px;
            // }
            // @media screen and (max-width: 991px) {
            //     bottom: -63px;
            // }
        }
        ul {
            li {
                &:not(:last-child) {
                    &:after {
                        left: -70%;
                        right: auto;
                        transform: translateX(50%);
                    }
                }
            }
        }
        @media screen and (max-width: 575px) {
            ul {
                li {
                    &:not(:last-child) {
                        &:after {
                            left: auto;
                            right: 16px;
                        }
                    }
                    span {
                        margin: 0 0 0 rem(16px);
                    }
                }
            }
        }
    }
}
</style>
