<template>
    <div class="submit-idea-tabs">
        <b-tabs v-if="isTabsAvailable" content-class="mt-3" ref="tabs" lazy>
            <div
                v-for="(bucket, index) in bucketCategoriesList.results"
                :key="index"
            >
                <b-tab
                    :title="bucket[`name_${$i18n.locale}`]"
                    @click="clickTab(bucket.slug)"
                    :active="myIdea.selected_bucket === bucket.slug"
                    v-if="
                        targetTab[bucket.name.replace(/ /g, '_')] &&
                            bucket.enable
                    "
                >
                    <template v-if="!loadding">
                        <label
                            v-for="(category, index) in ideaOpcoCategories"
                            :key="`${bucket.name}-${index}`"
                            class="container"
                        >
                            <div
                                class="category-card"
                                :class="{
                                    'selected-category-card':
                                        myIdea.category === category,
                                }"
                            >
                                <input
                                    type="radio"
                                    :id="`${bucket.name}-${index}`"
                                    v-model="myIdea.category"
                                    :value="category"
                                    @change="selectCategory(category)"
                                    :required="true"
                                />
                                <div class="card-data">
                                    <div class="card-title">
                                        <b>{{ category.title }}</b>
                                    </div>
                                    <div class="card-description">
                                        {{ category.description }}
                                    </div>
                                </div>
                            </div>
                        </label>
                    </template>
                    <div v-if="ideaOpcoCategories.length === 0 && !loadding">
                        {{ $t('No Ideas found for selected category') }}
                    </div>
                    <base-loader v-if="loadding" />
                </b-tab>
            </div>
        </b-tabs>
        <b-form-invalid-feedback v-if="categoryError.length">
            {{ categoryError }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
import { IdeasHelper } from '@/common/crud-helpers/ideas'
import { apiBase } from '@/conf'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    props: {
        myIdea: {
            type: Object,
        },
        categoryError: {
            type: String,
        },
        countries: {
            type: Array,
            required: true,
        },
    },
    components: {},
    data() {
        return {
            loadding: false,
            ideaOpcoCategories: [],
            activeTabValue: {},
            isTabsAvailable: false,
            targetTab: {},
        }
    },
    created() {
        this.bucketCategoriesList.results.forEach(tab => {
            this.activeTabValue[tab.name_en] = tab.slug
            this.activeTabValue[tab.name_ar] = tab.slug
        })

        if (this.myIdea.selected_bucket) {
            this.getIdeas()
        } else {
            this.clickTab(this.bucketCategoriesList.results[0].slug)
        }
        this.getTabs()
    },
    methods: {
        clickTab(bucket) {
            this.myIdea.selected_bucket = bucket
            this.myIdea.category = {}
            this.getIdeas()
        },
        selectCategory(val) {
            this.myIdea.category = { ...val }
        },
        async getTabs() {
            this.loadding = true
            this.isTabsAvailable = false
            Object.keys(this.targetTab).forEach(key => {
                this.targetTab[key] = false
            })
            await axios
                .get(
                    `${apiBase()}idea/opco-categories/?country=${
                        this.myIdea.country
                    }`,
                )
                .then(resp => {
                    if (resp.data.length > 0) {
                        resp.data.forEach(item => {
                            this.targetTab[
                                item.bucket.replace(/ /g, '_')
                            ] = true
                        })
                    }
                    this.isTabsAvailable = true
                    setTimeout(() => {
                        if (this.$refs.tabs.tabs[this.$refs.tabs.currentTab]) {
                            this.myIdea.selected_bucket = this.activeTabValue[
                                this.$refs.tabs.tabs[
                                    this.$refs.tabs.currentTab
                                ].title
                            ]
                            this.getIdeas()
                        }
                    }, 500)
                })
            // this.$nextTick(() => {
            //     console.log(this.$refs.tabs)
            //     if (this.$refs.tabs.tabs[this.$refs.tabs.currentTab]) {
            //         this.myIdea.selected_bucket = this.activeTabValue[
            //             this.$refs.tabs.tabs[this.$refs.tabs.currentTab].title
            //         ]
            //         // .toLowerCase()
            //         // .replace(/ /g, '_')
            //         this.getIdeas()
            //     }
            // })
        },
        getIdeas() {
            this.loadding = true
            IdeasHelper.getOpcoIdeaCategory({
                country: this.myIdea.country,
                bucket: this.myIdea.selected_bucket
                    ? this.myIdea.selected_bucket
                    : '',
            })
        },
    },
    computed: {
        ...mapGetters(['opcoIdeaCategories', 'bucketCategoriesList']),
    },
    watch: {
        opcoIdeaCategories() {
            this.ideaOpcoCategories = cloneDeep(this.opcoIdeaCategories)
            this.loadding = false
        },
        'myIdea.country'() {
            this.myIdea.category = {}
            this.getTabs()
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/ {
    .tabs:not(.ideas-tabs) > div > .nav-tabs > .nav-item .nav-link {
        text-align: center;
    }
    .tab-content {
        box-shadow: 1px 1px 11px 0px #00000033 !important;
        @media screen and (max-width: 767px) {
            padding: 1rem 0 0 !important;
        }
    }
    .tab-pane {
        height: 35rem;
        overflow-y: scroll;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .tab-pane::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .tab-pane {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    input[type='radio']:checked {
        background-color: var(--secondary);
        border-radius: 50%;
        width: 14px;
        height: 14px;
        -webkit-appearance: none;
    }
}
.invalid-feedback {
    display: block;
    margin-top: 1rem;
}
.submit-idea-tabs {
    margin-bottom: 3.5rem;
}
.category-card {
    display: flex;
    align-items: center;
    width: 100%;
    // height: 7rem;
    box-shadow: -5px 5px 6px #00000026;
    border: solid 1px #e7e7e7;
    border-radius: 10px;
    padding: 1rem 1rem 1rem 1rem;
    .card-data {
        width: 100%;
        height: 100%;
        padding: 0 1rem 0 1rem;
        display: grid;
        .card-title {
            font-size: 1.2rem;
            // overflow: hidden;
            // text-overflow: ellipsis;
            display: -webkit-box;
            // -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .card-description {
            font-size: 1.1rem;
            line-height: 1.3rem;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 3;
            // -webkit-box-orient: vertical;
            white-space: pre-line;
        }
    }
}
.selected-category-card {
    border: solid 1px var(--secondary);
    box-shadow: -5px 5px 6px #ff7cca;
}
</style>
